<template>
  <div ref="overflowContainer" class="overflow-menu-container">
    <div ref="overflowTriggerContainer" class="overflow-trigger-container" @click="toggleIsOpen">
      <slot name="trigger" :isOpen="isOpen" />
    </div>
    <div
      ref="menu"
      class="overflow-menu"
      :class="{ 'is-open': isOpen }"
    >
      <slot />
    </div>
  </div>
</template>

<script>

  import EventBus from '@/components/utils/EventBus.vue'

  export default {
    props: {
      forceAlignRight: {
        type: Boolean,
        default: false,
      },
      forceAlignBottom: {
        type: Boolean,
        default: false,
      },
      // this is the number of pixels to place between the trigger and the menu
      menuMargin: {
        type: Number,
        default: 4,
      },
    },
    data() {
      return {
        isOpen: false,
        alignRight: false,
        alignBottom: false,
      }
    },
    computed: {
      $body() {
        return document.body
      },
      $html() {
        return document.querySelector('html')
      },
      $helpScoutBeaconButton() {
        return document.querySelector('.BeaconFabButtonFrame')
      },
      $overflowContainer() {
        return this.$refs.overflowContainer
      },
      $overflowTriggerContainer() {
        return this.$refs.overflowTriggerContainer
      },
      $menu() {
        return this.$refs.menu
      },
    },
    watch: {
      $route() {
        this.closeMenu()
      },
      isOpen(newValue, oldValue) {

        this.$emit('on-open-change', newValue)

        const overflowTriggerButton = this.$overflowTriggerContainer.querySelector(':scope > button')

        if (!newValue) {
          this.removeEventListeners()
          EventBus.$emit('overflow-menu:closed', this.$overflowContainer)
          if (overflowTriggerButton) overflowTriggerButton.classList.remove('active')
          return
        }

        // figure out the width of the hidden menu by quickly un-hiding it,
        //  grabbing it's DOMRect, and then hiding it again
        this.$menu.style.display = 'block'

        const menuRect = this.$menu.getBoundingClientRect()
        const containerRect = this.$overflowContainer.getBoundingClientRect()

        this.$menu.style.display = ''

        // start by assuming the menu can be left-aligned and top-aligned
        this.$menu.style.left = `${containerRect.left}px`
        this.$menu.style.top = `${containerRect.bottom + this.menuMargin + window.scrollY}px`

        const menuTopIfAlignedBottom = `${containerRect.top - menuRect.height - this.menuMargin + window.scrollY}px`

        // if showing it left-aligned at the calculated width would put it off
        //  the right side of the screen, right-align it instead
        if (this.forceAlignRight || containerRect.left + menuRect.width >= this.$html.clientWidth) {
          this.$menu.style.left = `${containerRect.right - menuRect.width}px`
        }

        // if showing it top-aligned at the calculated height would put it off
        //  the bottom of the screen, bottom-align it instead
        if (this.forceAlignBottom || containerRect.bottom + menuRect.height >= this.$html.clientHeight) {
          this.$menu.style.top = menuTopIfAlignedBottom
        }

        // finally, check if the menu would overlap the HelpScout beacon, if so
        //  bottom-align it instead
        if (this.$helpScoutBeaconButton) {
          const helpScoutBeaconButtonRect = this.$helpScoutBeaconButton.getBoundingClientRect()
          if (
            containerRect.bottom < helpScoutBeaconButtonRect.bottom
            && containerRect.bottom + menuRect.height >= helpScoutBeaconButtonRect.top

            && containerRect.left < helpScoutBeaconButtonRect.right
            && containerRect.left + menuRect.width >= helpScoutBeaconButtonRect.right
          ) {
            this.$menu.style.top = menuTopIfAlignedBottom
          }
        }

        this.addEventListeners()

        EventBus.$emit('overflow-menu:opened', this.$overflowContainer)

        if (overflowTriggerButton) overflowTriggerButton.classList.add('active')

      },
    },
    mounted() {
      if (this.$menu) {
        // @NOTE: no need to call this.$overflowContainer.removeChild(this.$menu)
        //  since the appendChild() call below will literally move the element
        //  to the body
        this.$body.appendChild(this.$menu)
      }
      EventBus.$on('overflow-menu:opened', this.onGlobalOverflowMenuOpen)
    },
    beforeUnmount() {
      this.removeEventListeners()
      if (this.$menu) this.$body.removeChild(this.$menu)
      EventBus.$off('overflow-menu:opened', this.onGlobalOverflowMenuOpen)
    },
    methods: {
      closeMenu() {
        this.isOpen = false
      },
      toggleIsOpen() {
        this.isOpen = !this.isOpen
      },
      addEventListeners() {
        window.addEventListener('resize', this.closeMenu)
        this.$body.addEventListener('mousedown', this.closeMenu)
        this.$menu.addEventListener('mousedown', this.stopPropagation)
        this.$overflowContainer.addEventListener('mousedown', this.stopPropagation)
      },
      removeEventListeners() {
        this.closeMenu()
        window.removeEventListener('resize', this.closeMenu)
        this.$body.removeEventListener('mousedown', this.closeMenu)
        this.$menu.removeEventListener('mousedown', this.stopPropagation)
        this.$overflowContainer.removeEventListener('mousedown', this.stopPropagation)
      },
      stopPropagation($event) {
        $event.stopPropagation()
      },
      // this method is called whenever an overflow menu is opened site-wide and
      //  lets us close this local overflow menu in response
      onGlobalOverflowMenuOpen($overflowContainer) {
        if ($overflowContainer !== this.$overflowContainer) {
          this.closeMenu()
        }
      },
    },
  }

</script>

<style lang="stylus">

  // @NOTE: styles imported globally in main.js

</style>
