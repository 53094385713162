<template>

  <div
    v-show="show"
    ref="detailsContainer"
    class="details-container patch-overview"
  >

    <div class="details">

      <section>
        <strong>Trigger</strong>
        <div class="details-list with-border">

          <div v-if="trigger">
            <strong>Type:</strong>
            <img class="inline-icon" :src="trigger.iconUrl" />
            <span>{{ trigger.name }}</span>
          </div>

          <div v-if="patch.network">
            <strong>{{ createPatchFormFields.networkId.label }}:</strong>
            <img class="inline-icon" :src="patch.network.iconUrl" />
            <span>{{ patch.network.name }}</span>
          </div>

          <div v-if="patch.contract">
            <strong>{{ contractLabel }}:</strong>
            <img class="inline-icon" :src="patch.contract.iconUrl" />
            <span>{{ userContractNickname || patch.contract.name }}</span>
          </div>

          <template v-if="trigger.type === 'balance-change'">
            <div v-if="trigger.slug === 'balance-threshold-reached'">
              <strong>{{ createPatchFormFields.addressBalanceChangeThreshold.label }}:</strong>
              <span>Goes {{ addressBalanceChangeThresholdDirection }} {{ addressBalanceChangeThresholdValue }}</span>
            </div>
            <div v-if="balanceChangeAddresses.length === 1">
              <strong>Address:</strong>
              <span class="break-all">{{ getTriggerSettingBySlug(patch, 'address-list').value }}</span>
            </div>
            <div v-else>
              <strong>Addresses:</strong>
              <div class="break-all">
                <ol>
                  <li
                    :key="address"
                    v-for="address in balanceChangeAddresses"
                  >
                    {{ address }}
                  </li>
                </ol>
              </div>
            </div>
          </template>

          <template v-if="trigger.type === 'v2-dex'">
            <!-- no other settings to show -->
          </template>

          <template v-if="trigger.type === 'v3-dex'">
            <!-- no other settings to show -->
          </template>

          <template v-if="trigger.type === 'nft'">
            <!-- no other settings to show -->
          </template>

          <template v-if="trigger.type === 'user-contract'">
            <div>
              <strong>{{ createPatchFormFields.userContractType.label }}:</strong>
              <img class="inline-icon" :src="patch.network.iconUrl" />
              <span>{{ patch.contract.type === 'other' ? titleCase(patch.contract.type) : patch.contract.type.toUpperCase() }}</span>
            </div>
            <div>
              <strong>{{ createPatchFormFields.userContractAddress.label }}:</strong>
              <img class="inline-icon" :src="patch.contract.iconUrl" />
              <span class="break-all">{{ patch.contract.address }}</span>
            </div>
            <div class="stacked-item" v-if="userContractProxyEvents.length !== 0">
              <strong class="flex items-baseline">
                <!-- <span>{{ formFields.userContractProxyEvents.label }}</span> -->
                <span>Events</span>
                <span class="badge purple subtle ml-2">PROXY</span>
              </strong>
              <div class="break-all">
                <ol>
                  <li
                    class="mb-1"
                    :key="eventSignature"
                    v-for="eventSignature in userContractProxyEvents"
                  >
                    <ContractABIEntrySignature :abiEntrySignature="eventSignature" />
                  </li>
                </ol>
              </div>
            </div>
            <div class="stacked-item" v-if="userContractImplementationEvents.length !== 0">
              <strong class="flex items-baseline">
                <span>Events</span>
                <!--
                  only show a colon after the event label if we're NOT
                  showing the IMPLEMENTATION badge (because it looks weird
                  to have the badge AND colon there, but it looks weird to
                  NOT have the colon if the badge isn't there since
                  everything above has a colon...)
                -->
                <template v-if="userContractProxyEvents.length === 0">:</template>
                <template v-else>
                  <span class="badge purple subtle ml-2">IMPLEMENTATION</span>
                </template>
              </strong>
              <div class="break-all">
                <ol>
                  <li
                    class="mb-1"
                    :key="eventSignature"
                    v-for="eventSignature in userContractImplementationEvents"
                  >
                    <ContractABIEntrySignature :abiEntrySignature="eventSignature" />
                  </li>
                </ol>
              </div>
            </div>
            <div class="stacked-item" v-if="userContractProxyFunctions.length !== 0">
              <strong class="flex items-baseline">
                <!-- <span>{{ formFields.userContractProxyFunctions.label }}</span> -->
                <span>Functions</span>
                <span class="badge purple subtle ml-2">PROXY</span>
              </strong>
              <div class="break-all">
                <ol>
                  <li
                    class="mb-1"
                    :key="functionSignature"
                    v-for="functionSignature in userContractProxyFunctions"
                  >
                    <ContractABIEntrySignature :abiEntrySignature="functionSignature" />
                  </li>
                </ol>
              </div>
            </div>
            <div class="stacked-item" v-if="userContractImplementationFunctions.length !== 0">
              <strong class="flex items-baseline">
                <span>Functions</span>
                <!--
                  only show a colon after the function label if we're NOT
                  showing the IMPLEMENTATION badge (because it looks weird
                  to have the badge AND colon there, but it looks weird to
                  NOT have the colon if the badge isn't there since
                  everything above has a colon...)
                -->
                <template v-if="userContractProxyFunctions.length === 0">:</template>
                <template v-else>
                  <span class="badge purple subtle ml-2">IMPLEMENTATION</span>
                </template>
              </strong>
              <div class="break-all">
                <ol>
                  <li
                    class="mb-1"
                    :key="functionSignature"
                    v-for="functionSignature in userContractImplementationFunctions"
                  >
                    <ContractABIEntrySignature :abiEntrySignature="functionSignature" />
                  </li>
                </ol>
              </div>
            </div>
          </template>

        </div>
      </section>

      <section>
        <strong>Action</strong>
        <div class="details-list with-border">

          <div v-if="action">
            <strong>Type:</strong>
            <img class="inline-icon" :src="action.iconUrl" />
            <span>{{ action.name }}</span>
          </div>

          <div>
            <strong>Event:</strong>
            <span>{{ actionEvent }}</span>
          </div>

          <template v-if="action.slug === 'email'">
            <div>
              <strong>{{ createPatchFormFields.email.label }}:</strong>
              <span class="break-all">{{ getActionSettingBySlug(patch, 'email-address').value }}</span>
            </div>
          </template>

          <template v-if="action.slug === 'webhook'">
            <div>
              <strong>{{ createPatchFormFields.webhookAccountIntegrationId.label }}:</strong>
              <span>{{ accountIntegration.name }}</span>
            </div>
            <div>
              <strong>{{ createPatchFormFields.webhookUrl.label }}:</strong>
              <span class="break-all">{{ accountIntegration.defaultOutput }}</span>
            </div>
          </template>

          <template v-if="action.slug === 'discord'">
            <div>
              <strong>{{ createPatchFormFields.discordAccountIntegrationId.label }}:</strong>
              <span>{{ accountIntegration.name }}</span>
            </div>
            <div v-if="accountIntegration.serverId">
              <strong>{{ createPatchFormFields.discordChannelId.label }}:</strong>
              <span>{{ accountIntegrationSetting.value }}</span>
            </div>
          </template>

          <template v-if="action.slug === 'telegram'">
            <div>
              <strong>{{ createPatchFormFields.telegramAccountIntegrationId.label }}:</strong>
              <span>{{ accountIntegration.name }}</span>
            </div>
          </template>

          <template v-if="action.slug === 'dispatch-monitor'">
            <!-- no other settings to show -->
          </template>

        </div>
      </section>

      <section>
        <strong>Patch</strong>
        <div class="details-list with-border">
          <div>
            <strong>Patch ID:</strong>
            <span>{{ patch.id }}</span>
          </div>
          <div>
            <strong>Last updated:</strong>
            <span>{{ formatTimestamp(patch.updatedAt) }}</span>
          </div>
        </div>
      </section>

    </div>

  </div>

</template>

<script>

  import { mapGetters } from 'vuex'

  import useFilters from '@/composables/useFilters'

  import ContractABIEntrySignature from '@/components/etc/ContractABIEntrySignature.vue'

  export default {
    inject: ['$mixpanel'],
    components: {
      ContractABIEntrySignature,
    },
    props: {
      patch: {
        type: Object,
        required: true,
      },
      show: {
        type: Boolean,
        required: true,
      },
    },
    setup(props) {

      // composables
      const { titleCase, formatTimestamp, formatNumber, formatNumberAsUSD } = useFilters()

      return Object.assign({
        titleCase,
        formatNumber,
        formatTimestamp,
        formatNumberAsUSD,
      })

    },
    computed: {
      ...mapGetters('user', ['getUserContractOptionByContractId']),
      ...mapGetters('app', ['getActionSettingBySlug', 'getTriggerSettingBySlug']),
      action() {
        return this.patch.action
      },
      trigger() {
        return this.patch.trigger
      },
      createPatchFormFields() {
        return this.$store.state.forms.createPatchForm.fields
      },
      accountIntegration() {
        return this.accountIntegrationSetting.integration || {}
      },
      accountIntegrationSetting() {
        return this.getActionSettingBySlug(this.patch, 'integration') || {}
      },
      contractLabel() {
        switch (this.trigger.type) {
          case 'nft': return 'Collection'
          case 'balance-change': return 'Token'
          default: return 'Contract'
        }
      },
      balanceChangeAddresses() {
        return (this.getTriggerSettingBySlug(this.patch, 'address-list').value || '').split(',')
      },
      addressBalanceChangeThresholdDirection() {

        const aboveThreshold = this.getTriggerSettingBySlug(this.patch, 'above-threshold').value || ''
        const belowThreshold = this.getTriggerSettingBySlug(this.patch, 'below-threshold').value || ''

        if (aboveThreshold && belowThreshold) {
          return 'above or below'
        }

        if (aboveThreshold) {
          return 'above'
        }

        return 'below'

      },
      addressBalanceChangeThresholdValue() {
        const aboveThreshold = this.getTriggerSettingBySlug(this.patch, 'above-threshold').value || ''
        const belowThreshold = this.getTriggerSettingBySlug(this.patch, 'below-threshold').value || ''
        return this.formatNumber(aboveThreshold || belowThreshold)
      },
      userContractProxyEvents() {
        const userContractProxyEventsSetting = this.getTriggerSettingBySlug(this.patch, 'proxy-event-list').value
        return userContractProxyEventsSetting ? userContractProxyEventsSetting.split(/,(?! )/) : []
      },
      userContractImplementationEvents() {
        const userContractImplementationEventsSetting = this.getTriggerSettingBySlug(this.patch, 'implementation-event-list').value
        return userContractImplementationEventsSetting ? userContractImplementationEventsSetting.split(/,(?! )/) : []
      },
      userContractProxyFunctions() {
        const userContractProxyFunctionsSetting = this.getTriggerSettingBySlug(this.patch, 'proxy-function-list').value
        return userContractProxyFunctionsSetting ? userContractProxyFunctionsSetting.split(/,(?! )/) : []
      },
      userContractImplementationFunctions() {
        const userContractImplementationFunctionsSetting = this.getTriggerSettingBySlug(this.patch, 'implementation-function-list').value
        return userContractImplementationFunctionsSetting ? userContractImplementationFunctionsSetting.split(/,(?! )/) : []
      },
      userContractNickname() {
        if (!this.patch.contract) return null
        return (this.getUserContractOptionByContractId(this.patch.contract.id) || {}).label
      },
      actionEvent() {

        switch (this.action.slug) {
          case 'email':
            return this.createPatchFormFields.emailActionEvent.value

          case 'webhook': {
            return this.createPatchFormFields.webhookActionEvent.value
          }

          case 'discord': {

            const dmChannelOption = this.createPatchFormFields.discordActionEvent.options.find((option) => {
              return option.value === 'dm-channel'
            })

            const serverChannelOption = this.createPatchFormFields.discordActionEvent.options.find((option) => {
              return option.value === 'server-channel'
            })

            return this.accountIntegration.serverId !== null
              ? serverChannelOption.label
              : dmChannelOption.label

          }

          case 'telegram': {

            const channel = this.accountIntegration.defaultOutput || ''

            const privateChatOption = this.createPatchFormFields.telegramActionEvent.options.find((option) => {
              return option.value === 'private'
            })

            const groupChatOption = this.createPatchFormFields.telegramActionEvent.options.find((option) => {
              return option.value === 'group'
            })

            return !channel.startsWith('-')
              ? privateChatOption.label
              : groupChatOption.label

          }

          case 'dispatch-monitor':
            return this.createPatchFormFields.dispatchMonitorActionEvent.value

          default:
            return null
        }

      },
    },
    watch: {
      show(newValue, oldValue) {
        if (!newValue) return
        this.$mixpanel.onReady((mixpanel) => {
          mixpanel.track('Patch Details Viewed', {
            patch: this.patch,

            actionName: this.action.name,
            triggerName: this.trigger.name,
            networkName: this.patch.network ? this.patch.network.name : null,
            contractName: this.patch.contract ? this.patch.contract.name : null,

            actionSlug: this.action.slug,
            triggerSlug: this.trigger.slug,
            networkSlug: this.patch.network ? this.patch.network.slug : null,
            contractSymbol: this.patch.contract ? this.patch.contract.symbol : null,

            contractOwner: this.patch.contract ? this.patch.contract.owner : null,
            contractAddress: this.patch.contract ? this.patch.contract.address : null,
            contractImplementationAddress: this.patch.contract ? this.patch.contract.implementationAddress : null,

          })
        })
      },
    },
  }

</script>

<style lang="stylus" scoped>

  @import '~@/assets/styles/table-row-details.styl'

</style>
