<template>

  <div class="table-row clickable" :class="{ 'is-open': showDetails }" @click="toggleDetails">
    <div class="small">
      <button
        @click.stop="toggleDetails"
        :class="{ 'is-open': showDetails }"
        class="toggle-details-button small ghost"
      >
        <ChevronIcon />
      </button>
    </div>
    <div class="icons">
      <img :src="patch.iconUrl" />
      <ArrowIcon class="arrow-icon" />
      <img :src="patch.action.iconUrl" />
    </div>
    <div class="flex-grow">
      {{ patch.name }}
    </div>
    <div>
      <template v-if="arePatchesPseudoPaused">
        <Tooltip>
          <template #trigger>
            <input
              @click.stop
              class="toggle"
              type="checkbox"
              v-model="isActive"
              @change="updateIsActive"
              :disabled="isLoading || isAPIReadOnly || arePatchesPseudoPaused"
            />
          </template>
          <p>
            Your Patches are paused until {{ usageCycleEndsAt }} — when your Action usage resets.
          </p>
          <p>
            You can create new Patches, but you won't be able to turn them on until then.
          </p>
          <p>
            <router-link to="/account/usage-and-billing">View usage</router-link>
          </p>
        </Tooltip>
      </template>
      <template v-else>
        <input
          @click.stop
          class="toggle"
          type="checkbox"
          v-model="isActive"
          @change="updateIsActive"
          :disabled="isLoading || isAPIReadOnly || arePatchesPseudoPaused"
        />
      </template>
    </div>
    <div>
      {{ formatNumber(patch.actionCount) }}
    </div>
    <div>
      {{ formatNumber(patch.errorCount) }}
    </div>
    <div class="timestamp">
      {{ formatTimestamp(patch.insertedAt) }}
    </div>
    <div class="small">
      <OverflowMenu :forceAlignRight="true" @click.stop>
        <template #trigger>
          <button class="small ghost"><EllipsisIcon /></button>
        </template>
        <!--
          @TODO: remove this conditional when dex and/or nft stuff is ready to
          be pushed live
        -->
        <button :disabled="isAPIReadOnly" class="link" @click="duplicatePatch" v-if="!(patch.trigger.type === 'v2-dex' || patch.trigger.type === 'v3-dex' || patch.trigger.slug === 'nft-collection-items-transferred')"><CopyIcon /> Make a copy</button>
        <button :disabled="isAPIReadOnly" class="link" @click="openRenamePatchModal"><RenameIcon /> Rename</button>
        <button :disabled="isAPIReadOnly" class="link delete-button" @click="openDeletePatchModal"><TrashIcon /> Delete</button>
      </OverflowMenu>
    </div>
  </div>
  <PatchDetails
    :patch="patch"
    :show="showDetails"
  />

</template>

<script>

  import { mapState, mapGetters } from 'vuex'

  import useFilters from '@/composables/useFilters'

  import CopyIcon from '@/assets/icons/copy.svg'
  import ArrowIcon from '@/assets/icons/arrow.svg'
  import TrashIcon from '@/assets/icons/trash.svg'
  import RenameIcon from '@/assets/icons/rename.svg'
  import ChevronIcon from '@/assets/icons/chevron.svg'
  import EllipsisIcon from '@/assets/icons/ellipsis.svg'

  import Tooltip from '@/components/utils/Tooltip.vue'
  import OverflowMenu from '@/components/utils/OverflowMenu.vue'
  import PatchDetails from '@/components/tables/PatchDetails.vue'

  export default {
    emits: ['toggle'],
    inject: ['$mixpanel'],
    components: {
      Tooltip,
      CopyIcon,
      ArrowIcon,
      TrashIcon,
      RenameIcon,
      ChevronIcon,
      EllipsisIcon,
      OverflowMenu,
      PatchDetails,
    },
    props: {
      patch: {
        type: Object,
        required: true,
      },
    },
    setup() {

      const { formatNumber, formatTimestamp } = useFilters()

      return {
        formatNumber,
        formatTimestamp,
      }

    },
    computed: {
      ...mapState('app', ['isAPIReadOnly']),
      ...mapGetters('user', ['arePatchesPseudoPaused', 'usageCycleEndsAt']),
      ...mapGetters('app', ['getActionSettingBySlug', 'getTriggerSettingBySlug']),
    },
    watch: {
      'patch.isActive': function patchActive(newValue, oldValue) {
        this.isActive = newValue
      },
    },
    data() {
      return {
        isActive: false,
        isLoading: false,
        showDetails: false,
      }
    },
    created() {
      this.isActive = !this.arePatchesPseudoPaused && this.patch.isActive
    },
    methods: {
      toggleDetails() {
        this.showDetails = !this.showDetails
        this.$emit('toggle', this.showDetails)
      },
      updateIsActive() {

        if (this.arePatchesPseudoPaused) return

        this.isLoading = true

        this.$store.dispatch('api/UPDATE_USER_PATCH', { id: this.patch.id, isActive: this.isActive })
          .catch((error) => {
            this.$store.dispatch('toast/CREATE_TOAST', {
              text: 'Could not update Patch status! Please try again later.',
              type: 'error',
            })
          })
          .finally(() => {
            this.isLoading = false
          })

      },
      openDeletePatchModal() {

        this.$mixpanel.onReady((mixpanel) => {
          mixpanel.track('Patch Overview "Delete Patch" Button Clicked', {
            patch: this.patch,
          })
        })

        this.$store.dispatch('modals/OPEN_MODAL', {
          name: 'DeletePatchModal',
          props: {
            patch: this.patch,
            onSuccess: () => {
              // @NOTE: even though the patch was deleted, this component still
              //  has a reference to it in memory so this object is not empty
              this.$mixpanel.onReady((mixpanel) => {
                mixpanel.track('Patch Deleted', {
                  patch: this.patch,
                })
              })
            },
          },
        })

      },
      openRenamePatchModal() {

        const oldName = this.patch.name

        this.$mixpanel.onReady((mixpanel) => {
          mixpanel.track('Patch Overview "Rename Patch" Button Clicked', {
            patch: this.patch,
          })
        })

        this.$store.dispatch('modals/OPEN_MODAL', {
          name: 'RenamePatchModal',
          props: {
            patch: this.patch,
            onSuccess: () => {

              this.$mixpanel.onReady((mixpanel) => {
                mixpanel.track('Patch Renamed', {
                  newName: this.patch.name,
                  patch: this.patch,
                  oldName,
                })
              })

              this.$store.dispatch('toast/CREATE_TOAST', {
                text: 'Your Patch has been renamed!',
                type: 'success',
              })

            },
          },
        })

      },
      duplicatePatch() {

        const newTemplate = {}

        const { network, contract } = this.patch
        const integrationSetting = this.getActionSettingBySlug(this.patch, 'integration')

        newTemplate.actionId = this.patch.action.id
        newTemplate.triggerId = this.patch.trigger.id

        if (network) newTemplate.networkId = network.id
        if (contract) newTemplate.contractId = contract.id

        const addressList = this.getTriggerSettingBySlug(this.patch, 'address-list').value || ''

        switch (this.patch.trigger.slug) {

          case 'address-balance-change':
            newTemplate.addressBalanceChangeAddressList = addressList ? addressList.split(',') : []
            break

          case 'balance-threshold-reached': {

            newTemplate.addressBalanceChangeAddressList = addressList ? addressList.split(',') : []

            const aboveThreshold = this.getTriggerSettingBySlug(this.patch, 'above-threshold').value || ''
            const belowThreshold = this.getTriggerSettingBySlug(this.patch, 'below-threshold').value || ''

            newTemplate.addressBalanceChangeThresholdDirection = []
            newTemplate.addressBalanceChangeThreshold = aboveThreshold || belowThreshold

            if (aboveThreshold) {
              newTemplate.addressBalanceChangeThresholdDirection.push('above')
            }

            if (belowThreshold) {
              newTemplate.addressBalanceChangeThresholdDirection.push('below')
            }

            break

          }

          case 'smart-contract-activity': {

            if (contract) {

              delete newTemplate.contractId
              newTemplate.userContractId = contract.id

              const userContractProxyEventsSetting = this.getTriggerSettingBySlug(this.patch, 'proxy-event-list').value
              const userContractImplementationEventsSetting = this.getTriggerSettingBySlug(this.patch, 'implementation-event-list').value

              const userContractProxyFunctionsSetting = this.getTriggerSettingBySlug(this.patch, 'proxy-function-list').value
              const userContractImplementationFunctionsSetting = this.getTriggerSettingBySlug(this.patch, 'implementation-function-list').value

              newTemplate.userContractProxyEvents = userContractProxyEventsSetting ? userContractProxyEventsSetting.split(/,(?! )/) : []
              newTemplate.userContractImplementationEvents = userContractImplementationEventsSetting ? userContractImplementationEventsSetting.split(/,(?! )/) : []

              newTemplate.userContractProxyFunctions = userContractProxyFunctionsSetting ? userContractProxyFunctionsSetting.split(/,(?! )/) : []
              newTemplate.userContractImplementationFunctions = userContractImplementationFunctionsSetting ? userContractImplementationFunctionsSetting.split(/,(?! )/) : []

            }

            break

          }

          // the following triggers have no settings
          case 'new-elk-listing':
          case 'new-pangolin-listing':
          case 'new-quickswap-listing':
          case 'new-sushiswap-listing':
          case 'new-trader-joe-listing':
          case 'new-uniswap-v2-listing':
          case 'new-uniswap-v3-listing':
          case 'new-pancakeswap-listing':
          case 'nft-collection-items-transferred':
            break

          default: // do nothing

        }

        switch (this.patch.action.slug) {

          case 'email':
            newTemplate.email = this.getActionSettingBySlug(this.patch, 'email-address').value || ''
            break

          case 'telegram':
            newTemplate.telegramAccountIntegrationId = integrationSetting.integrationId
            newTemplate.telegramActionEvent = integrationSetting.integration.defaultOutput.startsWith('-') ? 'group' : 'private'
            break

          case 'discord':
            newTemplate.discordChannelId = integrationSetting.integrationChannelId || ''
            newTemplate.discordAccountIntegrationId = integrationSetting.integrationId
            newTemplate.discordActionEvent = integrationSetting.integration.serverId === null ? 'dm-channel' : 'server-channel'
            break

          case 'dispatch-monitor':
            // no settings
            break

          case 'webhook':
            newTemplate.webhookAccountIntegrationId = integrationSetting.integrationId || ''
            break

          default: // do nothing

        }

        this.$store.commit('forms/SET_FORM_TEMPLATE', {
          formName: 'createPatchForm',
          newTemplate,
        })

        this.$mixpanel.onReady((mixpanel) => {
          mixpanel.track('Patch Overview "Make a Copy" Button Clicked', {
            patch: this.patch,
            newTemplate,
          })
        })

        this.$router.push({
          name: 'PatchCreate',
          params: {
            referrerLink: 'patch-overview-duplicate-patch',
          },
        })

      },
    },
  }

</script>

<style lang="stylus" scoped>

  .delete-button
    @apply text-primary-500

    &:hover:not([disabled])
      @apply text-primary-500

</style>
