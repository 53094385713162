<template>

  <template v-if="isUserDataLoading || isBootstrapDataLoading">
    <div class="loading-icon-container">
      <LoadingIcon />
    </div>
  </template>

  <template v-else>

    <template v-if="paginationList.totalItems === 0 && !paginationList.hasFiltersOrQueryApplied">
      <EmptyNotice>
        <template #title>
          You don't have any Patches yet.
        </template>
        <template #message>
          Create your first blockchain-based automation by creating a Patch!
        </template>
      </EmptyNotice>
    </template>

    <template v-else>

      <PaginationFilters :filterFormName="filterFormName" :paginationListName="paginationListName" />

      <template v-if="paginationList.totalItems === 0">
        <div class="relative">
          <LoadingOverlay type="light" v-if="paginationList.isLoading" />
          <EmptyNotice :showActionButton="false" :showPatchTemplates="false">
            <template #image>
              <img src="@/assets/images/astronaut-sad.png" />
            </template>
            <template #title>
              Sorry, nothing matches your search.
            </template>
            <template #message>
              We couldn't find any Patches matching your search. Please try again.
            </template>
            <template #extra>
              <button class="link mt-4" @click="resetFilterForm">Clear search</button>
            </template>
          </EmptyNotice>
        </div>
      </template>

      <template v-else>
        <div class="table-container">
          <LoadingOverlay type="light" v-if="paginationList.isLoading" />
          <div class="table">
            <div class="table-header table-row">
              <div class="small"></div>
              <div>PATCH</div>
              <div class="flex-grow">NAME</div>
              <div>STATUS</div>
              <div>ACTIONS</div>
              <div>ERRORS</div>
              <div class="timestamp">CREATED</div>
              <div class="small"></div>
            </div>
            <PatchOverviewTableRow
              :patch="patch"
              :key="patch.id"
              v-for="patch in paginationList.currentPage"
            />
          </div>
        </div>
        <PaginationButtons :paginationListName="paginationListName" />
      </template>

    </template>

  </template>

</template>

<script>

  import { ref } from 'vue'
  import { mapState } from 'vuex'

  import usePaginationPolling from '@/composables/usePaginationPolling'
  import usePaginationFilters from '@/composables/usePaginationFilters'

  import EmptyNotice from '@/components/page/EmptyNotice.vue'
  import LoadingIcon from '@/components/utils/LoadingIcon.vue'
  import LoadingOverlay from '@/components/utils/LoadingOverlay.vue'
  import PaginationFilters from '@/components/utils/PaginationFilters.vue'
  import PaginationButtons from '@/components/utils/PaginationButtons.vue'
  import PatchOverviewTableRow from '@/components/tables/PatchOverviewTableRow.vue'

  export default {
    components: {
      LoadingIcon,
      EmptyNotice,
      LoadingOverlay,
      PaginationFilters,
      PaginationButtons,
      PatchOverviewTableRow,
    },
    computed: {
      ...mapState('app', ['isBootstrapDataLoading']),
      ...mapState('user', ['isUserDataLoading']),
    },
    setup() {

      const paginationListName = ref('userPatches')
      const filterFormName = ref('patchOverviewFiltersForm')

      const {
        filterForm,
        paginationList,
        resetFilterForm,
        numSelectedFilters,
      } = usePaginationFilters({ filterFormName, paginationListName })

      // @TODO: remove this when socket stuff is available
      usePaginationPolling({ paginationListName })

      return {
        filterForm,
        paginationList,
        filterFormName,
        resetFilterForm,
        paginationListName,
        numSelectedFilters,
      }

    },
    created() {
      this.$store.dispatch('pagination/RESET_PAGINATION', { listName: this.paginationListName })
    },
  }

</script>

<style lang="stylus" scoped>

  .loading-icon-container
    @apply my-16
    @apply flex
    @apply justify-center

</style>
